import { defineStore } from "pinia";
import { ICompanyData } from "./CompaniesStore";
import axios, { AxiosError } from 'axios'
import { IStoreData } from "@/models/StoreModel";
import router from "@/router";

type TCompany = ICompanyData | null
type TStore = IStoreData | null

interface IData {
  company: TCompany
  store: TStore
  updated: boolean
  error: boolean
}

interface IFilterBaseV2Store {
  updated: boolean
  error: boolean
  company: TCompany
  store: TStore
  refresh: boolean
  data: IData
}

export interface ICompanySessionResponse {
  data: {
    error: boolean
    message: string
    data: ICompanyData | null
    errorCode: string
  }
}

export interface IStoreSessionResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreData | null,
    errorCode: string,
  }
}

export const useFilterBaseV2Store = defineStore("FilterBaseV2Store", {
  state: (): IFilterBaseV2Store => {
    return {
      company: null,
      store: null,
      updated: false,
      error: false,
      refresh: false,
      data: {
        company: null,
        store: null,
        updated: false,
        error: false,
      },
    };
  },
  actions: {
    async getCompanyAndStoreInSession() {
      try {
        this.updated = false
        this.error = false

        const [ companySessionResponse, storeSessionResponse ] : [ ICompanySessionResponse, IStoreSessionResponse ] = [
          await axios.post('/api/getCompanySession'),
          await axios.post('/api/getStoreSession')
        ]

        if(this.getCompany?.id !== companySessionResponse.data.data?.id)
          this.updated = true

        if(this.getStore?.id !== storeSessionResponse.data.data?.id)
          this.updated = true

        this.company = companySessionResponse.data.data
        this.store = storeSessionResponse.data.data
      } catch (error) {
        this.updated = false
        this.error = true
        this.company = null
        this.store = null
        if (error instanceof AxiosError)
          throw new Error(error.response?.data.message || 'Algo deu errado!');
        else
          throw new Error('Algo deu errado!');
      }

      this.data = {
        updated: this.updated,
        error: this.error,
        company: this.company,
        store: this.store,
      }

      return this.data
    },
    async setCompanyAndStoreInSession(companyId: number | null, storeId: number | null) {
      try {
        this.refresh = false
        this.updated = false
        this.error = false

        const [ companySessionResponse, storeSessionResponse ] : [ ICompanySessionResponse, IStoreSessionResponse ] = [
          await axios.post('/api/setCompanySession', { id: !!companyId ? companyId : null }),
          await axios.post('/api/setStoreSession', { id: !!storeId ? storeId : null })
        ]

        if(this.getCompany?.id !== companySessionResponse.data.data?.id)
          this.updated = true

        if(this.getStore?.id !== storeSessionResponse.data.data?.id)
          this.updated = true

        this.company = companySessionResponse.data.data
        this.store = storeSessionResponse.data.data
      } catch (error) {
        this.updated = false
        this.error = true
        this.company = null
        this.store = null
        if (error instanceof AxiosError)
          throw new Error(error.response?.data.message || 'Algo deu errado!');
        else
          throw new Error('Algo deu errado!');
      } finally {
        this.refresh = true
      }

      this.data = {
        updated: this.updated,
        error: this.error,
        company: this.company,
        store: this.store,
      }

      return this.data
    },
    async getCompanyInSession(redirectToSetCompany = true, optimised = false) {
      if(this.company && optimised)
        return this.company

      await this.getCompanyAndStoreInSession()
      if(this.company)
        return this.company

      if(!this.company && redirectToSetCompany)
        router.push({ name: "FilterCompanySession" })

      return null
    },
    async getStoreInSession(redirectToSetCompany = true, optimised = false) {
      if(this.store && optimised)
        return this.store

      await this.getCompanyAndStoreInSession()
      if(this.store)
        return this.store

      if(!this.company && redirectToSetCompany)
        router.push({ name: "FilterCompanySession" })

      return null
    },
  },
  getters: {
    getCompany(): ICompanyData | null {
      return this.company
    },
    getStore(): IStoreData | null {
      return this.store
    },
    getData(): IData {
      return this.data
    },
    getRefresh(): boolean {
      return this.refresh
    }
  },
});
