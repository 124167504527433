
import axios, { AxiosError } from 'axios';
import { ICompanyData } from "@/store/CompaniesStore";
import { defineComponent, ref, onMounted, watch, Ref, onBeforeMount } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { campHandleCNPJ } from '@/composables/DataValidation';
import imgDefault from '@/assets/layout/default-noimgProduct2.png'
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedback,
  CampTable,
  CampTableTd,
  CampTableTh
} from '@/components';
import { Modal } from "bootstrap";

import { useProductStore } from '@/store/ProductStore';
import { useLoaderStore } from "@/store/LoaderStore";
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';

export default defineComponent({
  name: "StoreList",
  components: {
    CampHeader,
    CampEmptyListFeedback,
    CampTable,
    CampTableTd,
    CampTableTh,
    // modalRelationStore,
    // modalRelationCompetitor,
    // modalRelationCompany,
    // modalRelationProduct
  },
  setup() {
    const route = useRoute();
    const router = useRouter()

    const relationProps = ref(false)
    const relationData = ref(null)
    const productFilter = ref("")
    const queryProduct = ref()
    const loaderStore = useLoaderStore()
    const queryCategory = ref()
    const queryObject: Ref<any> = ref()
    const productStore = useProductStore()
    const isExcel = ref(false)
    const category = ref()
    const value1 = ref<Array<string>>([])
    const options = ref()
    const isLoadSelect = ref(false)
    const addSkuInputOption = ref('')
    const dialogVisible = ref(false)
    const filterBaseV2Store = useFilterBaseV2Store()

    async function getProductList() {
      queryProduct.value = productStore.getProduct
      // console.log(queryProduct.value)
      isExcel.value = productStore.getExcel
      try {
        const result = await axios.get(`/api/getCategory`)
        queryCategory.value = result.data.data
        category.value = result.data.data.category?.id

      } catch (error) {
        if (error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      }
    }

    function splitValue() {
      value1.value.forEach((elem, index) => {
        if (elem.split('').some(string => string === ',')) {
          value1.value.splice(index)
          value1.value.push(...elem.split(','))
        }
      })

    }

    async function geDataRelation() {
      if(!(filterBaseV2Store.getStore)) {
        showTimeAlert("É necessário ter uma loja setada", "error")
        return
      }
      isLoadSelect.value = true
      options.value = []
      try {
        const result = await axios.get(`/api/getStoreProduct/${filterBaseV2Store.getStore.id}`)
        const response = await axios.get('/api/getProductList')
        response.data.data.forEach(element => {
          if (!result.data.data.some(elem => elem.sku === element.sku)) {
            options.value.push({
              value: element.sku,
              label: element.name,
              url_media: element.url_media
            })
          }


        });
        isLoadSelect.value = false
      } catch (error) {
        isLoadSelect.value = false
        if (error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      }
    }

    let auxModal;

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    function funcDetailRelationDataProduct(data) {
      openModal('myModalProduct')
      relationProps.value = !relationProps.value
      relationData.value = data
    }

    function isValidated(data) {
      let isValidated = true
        for (const [ key, value ] of Object.entries(data)) {
          if(key === 'description'){
           return isValidated = true
          }
          if(key === 'category' && value === null) {
            return isValidated = false
          }
          if(value === '' && key !== 'url_media') {
              return isValidated = false
          }
        }
        return isValidated
    }

    function onSubmitSaveAll() {
      const removeDuplicate = queryProduct.value.original.filter(e => !e.registered)
      const isValidatedData = removeDuplicate.map(elem => {
        let isValidated = true
        for (const [ key, value ] of Object.entries(elem)) {
          if(key === 'description'){
           return isValidated = true
          }
          if(key === 'category' && value === '') {
            return isValidated = false
          }
          if(value === '' || value === null) {
            if(key !== 'url_media') {
              return isValidated = false
            }
          }
        }
        return isValidated
      })

      if(isValidatedData.some(elem => elem === false)) {
        return showTimeAlert('Preencha todos os campos marcados com "*"', 'error')
      } 
        dialogVisible.value = true

    }

    async function handleSubmit() {
      try {
        if(!(filterBaseV2Store.getStore)) {
          showTimeAlert("É necessário ter uma loja setada", "error")
          return
        }
        loaderStore.open()
        const removeDuplicate = queryProduct.value.original.filter(e => !e.registered)
        const result = await axios.post(`/api/postProductStore/${filterBaseV2Store.getStore.id}`, removeDuplicate)
        await router.push(`/lojas/produtos`)
        showTimeAlert(result.data.message)
        queryProduct.value = []
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    async function getProductValidation() {
      if(!(filterBaseV2Store.getStore)) {
        showTimeAlert("É necessário ter uma loja setada", "error")
        return
      }
      loaderStore.open()
          try {
            const response = await axios.post(`/api/verifyProductStore/${filterBaseV2Store.getStore.id}`, {products_sku: value1.value})
            productStore.getProductValidation(response.data.data, false)
            queryProduct.value = productStore.getProduct
            queryProduct.value.original = queryProduct.value.original.map(el => ({ ...el, order: (el.order ? el.order : 1) }))
          } catch (error) {
            if(error instanceof AxiosError) {

              showTimeAlert(error.response?.data.message, "error")
            }
          } finally {
            loaderStore.close()
          }
    }

    function deleteProduct(sku) {
      queryProduct.value.original = queryProduct.value.original.filter(elem => elem.sku !== sku)
    }

    function verifyDuplicate() {
      const removeDuplicate = queryProduct.value.original.filter(e => !e.registered)
      if(removeDuplicate.length >= 1) return false
      else return true
    }

    const { showTimeAlert } = useAlert()
    const itemDel = ref<any>(null)
    const refModalDel = ref<HTMLElement | null>(null)
    function handleSetItemDel(item: ICompanyData) {
      itemDel.value = item
    }
    function handleResetItemDel() {
      itemDel.value = null
    }
    async function handleConfirmItemDel() {
      if (itemDel.value) {
        try {
          const result = await axios.delete(`/api/deleteProductStore/${itemDel.value.sku}/${itemDel.value.id_store}`)
          showTimeAlert("Registro excluído com sucesso!")
          handleResetItemDel()
        } catch (error) {
          console.error(error)
          if (error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          refModalDel.value?.click()
        }
      }
    }

    onMounted(async () => {
      if(!(filterBaseV2Store.getStore)) {
        router.push({ name: "storeProductPut" })
        return
      }
      await getProductList()
      await geDataRelation()
      loaderStore.close()
    })

    watch(() => value1.value, () => {
      splitValue()
    })

    return {
      router,
      queryObject,
      handleSetItemDel,
      campHandleCNPJ,
      itemDel,
      handleConfirmItemDel,
      handleResetItemDel,
      refModalDel,
      relationData,
      relationProps,
      funcDetailRelationDataProduct,
      productFilter,
      queryProduct,
      queryCategory,
      productStore,
      category,
      isExcel,
      imgDefault,
      value1,
      options,
      isLoadSelect,
      addSkuInputOption,
      getProductValidation,
      handleSubmit,
      deleteProduct,
      isValidated,
      dialogVisible,
      onSubmitSaveAll,
      verifyDuplicate
    }
  }
})
